import { z } from 'zod'

export const PasswordSchema = z
	.string({ required_error: 'zod.passwordRequired' })
	.min(6, { message: 'zod.passwordTooShort' })
	.max(100, { message: 'zod.passwordTooLong' })
export const NameSchema = z
	.string({ required_error: 'zod.nameRequired' })
	.min(2, { message: 'zod.nameTooShort' })
	.max(40, { message: 'zod.nameTooLong' })
export const EmailSchema = z
	.string({ required_error: 'zod.emailRequired' })
	.email({ message: 'zod.emailInvalid' })
	.min(3, { message: 'zod.emailTooShort' })
	.max(100, { message: 'zod.emailTooLong' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'zod.passwordsMustMatch',
			})
		}
	})
